import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';

const { Paragraph } = Typography;

export const AddressAsText = ({ address }) => (
  <Space direction="vertical">
    <Paragraph>
      {address.companyName && (
        <>
          {address.companyName}
          <br />
        </>
      )}
      {address.firstName && (
        <>
          {`${address.firstName} ${address.lastName}`}
          <br />
        </>
      )}
      {address.streetAddress1 && (
        <>
          {address.streetAddress1}
          <br />
        </>
      )}
      {address.streetAddress2 && (
        <>
          {address.streetAddress2}
          <br />
        </>
      )}
      {address.city && (
        <>
          {`${address.city}, ${address.cityArea
          || address.city}, ${address.postalCode}`}
          <br />
        </>
      )}
      {address.country?.country && (
        <>
          {`${address.country?.country}, ${address.countryArea}`}
          <br />
        </>
      )}
      <strong>{address.phone}</strong>
    </Paragraph>
  </Space>
);

AddressAsText.propTypes = {
  address: PropTypes.shape({
    streetAddress1: PropTypes.string,
    streetAddress2: PropTypes.string,
    city: PropTypes.string,
    cityArea: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.shape({
      code: PropTypes.string,
      country: PropTypes.string,
    }),
    countryArea: PropTypes.string,
    phone: PropTypes.string,
    isDefaultShippingAddress: PropTypes.string,
    isDefaultBillingAddress: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
};
