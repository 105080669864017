import { gql } from '@apollo/client';
import { fragmentAddress } from '../fragments/fragmentAddress';
import { fragmentPageInfo } from '../fragments/fragmentPageInfo';

export const QUERY_ME = gql`
    query Me {
        me {
            id
            email
            firstName
            lastName
            defaultBillingAddress {
                ...fragmentAddress
            }
            addresses {
                ...fragmentAddress
            }
            metadata {
                key
                value
            }
            orders {
                totalCount
            }
        }
    }
    ${fragmentAddress}
`;

export const QUERY_ME_WISHLISTS = gql`
    query Me ($before: String, $after: String, $first: Int, $last: Int ) {
        me {
            id
            wishlists (before: $before, after: $after, first: $first, last: $last) {
                totalCount
                pageInfo {
                    ...fragmentPageInfo
                }
                edges {
                    node {
                        id
                        isActive @client
                        name
                        isArchived
                        createdAt
                        dateTimeRange
                        productVariants {
                            id
                        }
                    }
                }
            }
        }
    }
    ${fragmentPageInfo}
`;
