import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Result } from 'antd';
import { Link } from 'gatsby';
import { AuthForm } from '../auth-form';
import { inMemoryAuth } from '../in-memory-auth';
import { authValidate } from './utils/auth-validate';
import { CenteredSection } from '../../layout/sections/centered/centered-section';

export const PrivateRoute = ({
  Component,
  isStaff,
  permissions,
  ...rest
}) => {
  if (!inMemoryAuth.token) {
    return (
      <CenteredSection>
        <Alert
          message="Authentication Required"
          description="Please sign in to access this page"
          showIcon
          type="warning"
        />
        <AuthForm />
      </CenteredSection>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return authValidate(isStaff, permissions) ? <Component {...rest} /> : (
    <CenteredSection>
      <Result
        status="403"
        title="You are not authorized to access this page"
        extra={[
          <Link to="/">
            <Button size="large">
              Home
            </Button>
          </Link>,
          <Link to="/search">
            <Button size="large" type="primary">
              Start Searching
            </Button>
          </Link>,
        ]}
      />
    </CenteredSection>
  );
};

PrivateRoute.defaultProps = {
  permissions: [],
  isStaff: false,
};

PrivateRoute.propTypes = {
  Component: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.oneOf([
      'MANAGE_USERS',
      'MANAGE_STAFF',
      'MANAGE_SERVICE_ACCOUNTS',
      'MANAGE_APPS',
      'MANAGE_DISCOUNTS',
      'MANAGE_PLUGINS',
      'MANAGE_GIFT_CARD',
      'MANAGE_MENUS',
      'MANAGE_ORDERS',
      'MANAGE_PAGES',
      'MANAGE_PRODUCTS',
      'MANAGE_PRODUCT_TYPES_AND_ATTRIBUTES',
      'MANAGE_SHIPPING',
      'MANAGE_SETTINGS',
      'MANAGE_TRANSLATIONS',
      'MANAGE_CHECKOUTS',
    ]),
  ),
  isStaff: PropTypes.bool,
};
