import React from 'react';
import {
  Button, Card, Divider, notification, Space, Typography,
} from 'antd';
import { AiOutlineMail, AiOutlinePlus, AiOutlineUser } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { useQuery } from '@apollo/client';
import { IoIosConstruct } from 'react-icons/io';
import { SignOutButton } from '../../auth/sign-out/sign-out-button';
import { AddressAsText } from './address-as-text';
import css from './account-details.module.less';
import { QUERY_ME } from '../../../graphql/queries/me';
import { inMemoryAuth } from '../../auth/in-memory-auth';

const {
  Title,
  Text,
} = Typography;

export const AccountDetails = () => {
  const {
    data,
    loading,
  } = useQuery(QUERY_ME, {
    skip: !inMemoryAuth.token,
    fetchPolicy: 'cache-and-network',
  });

  const email = data?.me.email;
  const firstName = data?.me.firstName;
  const lastName = data?.me.lastName;
  const defaultBillingAddress = data?.me.defaultBillingAddress;

  const editProfile = () => {
    notification.warn({
      icon: <IoIosConstruct />,
      message: 'It\'s coming soon.',
      description: 'We\'re in beta, and this feature isn\'t quite ready yet.',
    });
  };

  return (
    <Card
      loading={loading}
      className={css.card}
      title={(
        <Title level={4}>
          <AiOutlineUser />
          {' '}
          Details
        </Title>
      )}
      extra={[
        <SignOutButton />,

        <Button
          onClick={editProfile}
          icon={<FiEdit />}
          type="text"
        >
          Edit
        </Button>,
      ]}
    >
      <Text strong>
        <AiOutlineMail />
        {' '}
        {email}
      </Text>
      <Title level={5}>
        <Space>
          {firstName || (<Button onClick={editProfile} type="dashed" icon={<AiOutlinePlus />}>Name</Button>)}
          {lastName || (<Button onClick={editProfile} type="dashed" icon={<AiOutlinePlus />}>Surname</Button>)}
        </Space>
      </Title>
      <Title level={5}>
        {firstName || (<Button onClick={editProfile} type="dashed" icon={<AiOutlinePlus />}>Phone</Button>)}
      </Title>
      <Divider />
      <Title level={5}>
        {firstName}
        {' '}
        {lastName}
      </Title>
      <Title level={5}>
        Billing details
      </Title>
      {defaultBillingAddress ? <AddressAsText address={defaultBillingAddress} /> : (
        <Button onClick={editProfile} type="dashed" icon={<AiOutlinePlus />}>Address</Button>
      )}
    </Card>
  );
};
