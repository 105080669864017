import { gql } from '@apollo/client';

export const WISHLIST_DELETE = gql`
    mutation WishlistDelete($id: ID!){
        wishlistDelete(id: $id) {
            wishlistErrors {
                field
                message
                code
            }
        }
    }`;
