import React from 'react';
import { Col, Row } from 'antd';
import { Wishlists } from './wishlists/wishlists';
import { AccountDetails } from './account/account-details';

export const UserLayout = () => (
  <>
    <Row gutter={[16, 64]}>
      <Col flex="1 1 375px">
        <AccountDetails />
      </Col>
      <Col flex="10 1 750px">
        <Wishlists />
      </Col>
    </Row>
  </>
);

UserLayout.propTypes = {};
