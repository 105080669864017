import React from 'react';
import { UserLayout } from '../../../components/user/user-layout';
import { BaseLayout } from '../../../components/layout/base/base-layout';
import { PrivateRoute } from '../../../components/auth/authorization/private-route';

const UserAccountPage = () => (
  <BaseLayout>
    <PrivateRoute Component={UserLayout} />
  </BaseLayout>
);

export default UserAccountPage;
