import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, message, notification, Popconfirm, Space,
} from 'antd';
import { AiOutlineCheckCircle, AiOutlineDelete } from 'react-icons/ai';
import { useMutation } from '@apollo/client';
import { BsArchive } from 'react-icons/bs';
import { WISHLIST_UPDATE } from '../../../graphql/mutations/wishlistUpdate';
import { PrivateComponent } from '../../auth/authorization/private-component';
import { WISHLIST_DELETE } from '../../../graphql/mutations/wishlistDelete';
import { actWishId, actWishVariants } from '../../../apollo/cache';
import { WISHLIST_ADD_VARIANTS } from '../../../graphql/mutations/wishlistAddVariants';
import { setActiveWishlist } from './utils/set-active-wishlist';

export const WishlistActions = ({
  item,
  postAction,
}) => {
  const [wishlistUpdate, { loading }] = useMutation(WISHLIST_UPDATE,
    {
      variables: {
        id: item.id,
        name: item.name,
        dateTimeRange: {
          gte: item.dateTimeRange?.[0],
          lte: item.dateTimeRange?.[1],
        },
        isArchived: !item.isArchived,
      },
      onCompleted: (data) => {
        if (data.wishlistUpdate.wishlistErrors.length === 0) {
          message.success(`List - ${item.name} - ${item.isArchived ? 'restored' : 'archived'}`);
        }
        postAction();
        return data.wishlistUpdate.wishlistErrors.forEach(
          ((err) => message.error(`Oops... ${err.message}`)),
        );
      },
    });

  const [wishlistDelete, { loading: loadingDelete }] = useMutation(WISHLIST_DELETE, {
    variables: {
      id: item.id,
    },
    onCompleted: (data) => {
      if (data.wishlistDelete.wishlistErrors.length === 0) {
        message.success('List removed');
        postAction();
        if (actWishId() === item.id) {
          notification.warning({
            message: 'No List Active',
            description: 'Please activate a list or create a new one',
          });
          actWishId(undefined);
        }
      }
      return data.wishlistDelete.wishlistErrors.forEach(
        ((err) => message.error(`Oops... ${err.message}`)),
      );
    },
  });

  const [wishlistAddVariants, { loading: loadingAdd }] = useMutation(WISHLIST_ADD_VARIANTS, {
    onCompleted: (data) => {
      if (data.wishlistAddVariants.wishlistErrors.length === 0) {
        notification.success({
          message: 'Favourites Saved',
          description: 'We saved your'
            + ' favourites to the list you selected. They are now safe on the server.',
        });
        const variantsIdList = data.wishlistAddVariants
          .wishlist.productVariants?.map((variant) => variant.id);
        actWishVariants(variantsIdList);
        localStorage.removeItem('actWishVariants');
        postAction();
      }
      return data.wishlistAddVariants.wishlistErrors.forEach(
        ((err) => message.error(`Oops... ${err.message}`)),
      );
    },
  });

  return (
    <Space>
      <PrivateComponent permissions={['MANAGE_USERS']}>
        <Popconfirm
          title={item.isArchived ? 'Restore the list?' : 'Archive the list?'}
          okText="Yes"
          cancelText="No"
          cancelButtonProps={{ size: 'large' }}
          okButtonProps={{
            loading,
            size: 'large',
          }}
          onConfirm={wishlistUpdate}
        >
          <Button type={item.isArchived ? 'primary' : 'dashed'} icon={<BsArchive />} />
        </Popconfirm>
      </PrivateComponent>
      <PrivateComponent permissions={['MANAGE_USERS']}>
        <Popconfirm
          title="Delete the list?"
          okText="Yes"
          cancelText="No"
          cancelButtonProps={{ size: 'large' }}
          okButtonProps={{
            loadingDelete,
            size: 'large',
          }}
          onConfirm={wishlistDelete}
        >
          <Button type="dashed" icon={<AiOutlineDelete />} />
        </Popconfirm>
      </PrivateComponent>
      <Button
        onClick={() => setActiveWishlist({
          item,
          wishlistAddVariants,
        })}
        loading={loadingAdd}
        type={item.isActive ? 'primary' : 'dashed'}
        icon={item.isActive ? <AiOutlineCheckCircle /> : null}
      >
        {item.isActive ? 'Active' : 'Activate'}
      </Button>
    </Space>
  );
};

WishlistActions.defaultProps = {
  postAction: () => {
  },
};

WishlistActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isArchived: PropTypes.bool,
    isActive: PropTypes.bool,
  }).isRequired,
  postAction: PropTypes.func,
};
