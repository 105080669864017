import {
  Alert, Button, Divider, Modal, PageHeader, Result, Spin, Table, Typography,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { BiBookAdd } from 'react-icons/bi';
import { FcIdea } from 'react-icons/all';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'gatsby';
import { QUERY_ME_WISHLISTS } from '../../../graphql/queries/me';
import { CenteredSection } from '../../layout/sections/centered/centered-section';
import { WishlistActions } from './wishlist-actions';
import css from './wishlists.module.less';
import { WishlistCreateForm } from './wishlist-create-form';
import { actWishId } from '../../../apollo/cache';

const {
  Title,
  Paragraph,
  Text,
} = Typography;

export const Wishlists = () => {
  const {
    data,
    loading,
    refetch,
  } = useQuery(
    QUERY_ME_WISHLISTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { first: 100 },
    },
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const wishlistId = useReactiveVar(actWishId);

  const columns = useMemo(() => ([
    {
      title: 'Title',
      dataIndex: 'name',
    },
    {
      title: 'Dates',
      dataIndex: 'dateTimeRange',
      width: 260,
      align: 'center',
      render: (text) => (text ? (
        <Typography.Text>
          {dayjs(text[0])
            .format('DD/MM/YYYY')}
          {' - '}
          {dayjs(text[1])
            .format('DD/MM/YYYY')}
        </Typography.Text>
      ) : '---'),
    },
    {
      title: 'Items',
      width: 60,
      align: 'center',
      key: 'items',
      dataIndex: 'productVariants',
      render: (text) => text.length,
    },
    {
      title: 'Created',
      width: 120,
      key: 'timestamp',
      align: 'center',
      dataIndex: 'createdAt',
      render: (text) => (
        <Typography>
          {dayjs(text)
            .format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Actions',
      width: '10%',
      align: 'center',
      key: 'action',
      render: (text) => (<WishlistActions item={text} postAction={refetch} />),
    },
  ]), [refetch]);

  const wishlistsData = data?.me?.wishlists?.edges.map(({ node }) => node);

  if (loading) {
    return <CenteredSection><Spin size="large" /></CenteredSection>;
  }

  const newListButton = (
    <Button
      key="new list"
      size="large"
      onClick={() => setIsModalVisible(true)}
      icon={<BiBookAdd />}
    >
      New Favourites List
    </Button>
  );

  return (
    <section>
      {wishlistsData?.length === 0 ? (
        <Result
          icon={<FcIdea />}
          title={(
            <Title level={3} className="text-center">
              Let
              {'\''}
              s start planning!
            </Title>
          )}
          subTitle={(
            <>
              <Paragraph strong>
                <Text mark>1. Create a name and date for your Event.</Text>
              </Paragraph>
              <Paragraph>
                2. Add your favorite suppliers to the event
              </Paragraph>
              <Paragraph>
                3. Contact them all with one click.
              </Paragraph>
            </>
          )}
          extra={[<Button type="primary" size="large" icon={<BiBookAdd />} onClick={() => setIsModalVisible(true)}>
            Create a List
          </Button>]}
        />
      ) : (
        <>
          {wishlistsData?.length < 3 && (
            <>
              <Result
                icon={<FcIdea />}
                title={(
                  <Title level={3} className="text-center">
                    Let
                    {'\''}
                    s start planning!
                  </Title>
                )}
                subTitle={(
                  <>
                    <Paragraph>
                      1. Create a name and date for your Event.
                    </Paragraph>
                    <Paragraph strong>
                      <Text mark>2. Add your favorite suppliers to the event</Text>
                    </Paragraph>
                    <Paragraph>
                      3. Contact them all with one click.
                    </Paragraph>
                  </>
                )}
                extra={[
                  <Link to="/search/">
                    <Button type="primary" size="large" icon={<AiOutlinePlus />}>
                      Find Suppliers
                    </Button>
                  </Link>]}
              />
              <Divider />
            </>
          )}

          <PageHeader
            className={css.pageHeader}
            title={(
              <Title level={2}>
                Your Lists
              </Title>
            )}
            extra={[newListButton]}
          >
            {!wishlistId
            && (
              <Alert
                message="Before you search, select the list you're working on by clicking 'Activate'."
                showIcon
                type="warning"
              />
            )}
            <Table
              dataSource={wishlistsData}
              loading={loading}
              columns={columns}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          </PageHeader>
        </>
      )}
      <Modal
        title="New Favourites List"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <WishlistCreateForm onCompleteCallback={() => {
          setIsModalVisible(false);
          return refetch();
        }}
        />
      </Modal>
    </section>
  );
};
