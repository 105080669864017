import { gql } from '@apollo/client';

export const WISHLIST_UPDATE = gql`
    mutation WishlistUpdate($id: ID!, $name: String, $isArchived: Boolean, $dateTimeRange: DateTimeRangeInput ){
        wishlistUpdate(id: $id, input: {name: $name, isArchived: $isArchived, dateTimeRange: $dateTimeRange}) {
            wishlistErrors {
                field
                message
                code
            }
        }
    }`;
