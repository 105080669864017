import React from 'react';
import { Button, message } from 'antd';
import { navigate } from 'gatsby';
import { BiLogOut } from 'react-icons/all';
import { inMemoryAuth } from '../in-memory-auth';
import { actWishId, actWishVariants } from '../../../apollo/cache';

export const SignOutButton = () => {
  const handleSignOut = async (e) => {
    e.stopPropagation();
    await navigate('/user/auth/');
    actWishId(undefined);
    actWishVariants([]);
    ['token', 'csrfToken', 'user', 'refreshToken'].forEach((key) => delete inMemoryAuth[key]);
    message.success('Successfully signed out.');
  };

  return (
    <Button icon={<BiLogOut />} type="text" onClick={handleSignOut}>
      Sign Out
    </Button>
  );
};
